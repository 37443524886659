import {init} from "./init";

init({
    modules: {
        admin: {
            routes: import(
                "./module/admin/admin.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "blend": {
            routes: import(
                /* webpackChunkName: "blend-module-route" */
                "./module/blend/blend.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "cleaning": {
            routes: import(
                /* webpackChunkName: "cleaning-module-route" */
                "./module/cleaning/cleaning.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "cropkind": {
            routes: import(
                /* webpackChunkName: "cropkind-module-route" */
                "./module/cropkind/cropkind.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "customer": {
            routes: import(
                /* webpackChunkName: "customer-module-route" */
                "./module/customer/customer.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "customerportal": {
            routes: import(
                /* webpackChunkName: "customer-portal-module-route" */
                "./module/customer-portal/customer-portal.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "document": {
            routes: import(
                /* webpackChunkName: "document-module-route" */
                "./module/document/document.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "inventory": {
            routes: import(
                /* webpackChunkName: "inventory-module-route" */
                "./module/inventory/inventory.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "notifier": {
            routes: import(
                /* webpackChunkName: "notifier-module-route" */
                "./module/notifier/notifier.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "orders": {
            routes: import(
                /* webpackChunkName: "orders-module-route" */
                "./module/orders/orders.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "packaging": {
            routes: import(
                /* webpackChunkName: "packaging-module-route" */
                "./module/packaging/packaging.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "permissionpanel": {
            routes: import(
                /* webpackChunkName: "permissionpanel-module-route" */
                "./module/permissionpanel/permissionpanel.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "qualityassurance": {
            routes: import(
                /* webpackChunkName: "qualityassurance-module-route" */
                "./module/qualityassurance/qualityassurance.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "rebill": {
            routes: import(
                /* webpackChunkName: "rebill-module-route" */
                "./module/rebill/rebill.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "receiving": {
            routes: import(
                /* webpackChunkName: "receiving-module-route" */
                "./module/receiving/receiving.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "seedactivity": {
            routes: import(
                /* webpackChunkName: "seedactivity-module-route" */
                "./module/seedactivity/seedactivity.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "report": {
            routes: import(
                /* webpackChunkName: "report-module-route" */
                "./module/report/report.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "seedlot": {
            routes: import(
                /* webpackChunkName: "seedlot-module-route" */
                "./module/seedlot/seedlot.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "seedline": {
            routes: import(
                /* webpackChunkName: "seedline-module-route" */
                "./module/seedline/seedline.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "analyte": {
            routes: import(
                /* webpackChunkName: "analyte-module-route" */
                "./module/analyte/analyte.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "test-category": {
            routes: import(
                /* webpackChunkName: "analyte-module-route" */
                "./module/test-category/test-category.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "shipping": {
            routes: import(
                /* webpackChunkName: "shipping-module-route" */
                "./module/shipping/shipping.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "staff": {
            routes: import(
                /* webpackChunkName: "staff-module-route" */
                "./module/staff/staff.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "treatment": {
            routes: import(
                /* webpackChunkName: "treatment-module-route" */
                "./module/treatment/treatment.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "warehouse": {
            routes: import(
                /* webpackChunkName: "warehouse-module-route" */
                "./module/warehouse/warehouse.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "customercontact": {
            routes: import(
                /* webpackChunkName: "customercontact-module-route" */
                "./module/customercontact/customercontact.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        "material": {
            routes: import(
                /* webpackChunkName: "material-module-route" */
                "./module/material/material.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
    },
    /**
     * There are scripts we want to run on all pages. Here we hook into the start up function which triggers after
     * we have processed the module routes but no necessary when all the routes have completed initializing, this is because
     * each module has it's own way of handling itself
     * @return {Promise<{default: *} | never>}
     */
    onModuleLoaded: () => {
        return import(/* webpackChunkName: "startup" */"./startup").then(({default: startUp}) => {
            return startUp();
        })
    }
});
